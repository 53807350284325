import dayjs from "dayjs/esm";
import {mean, median} from "d3-array";
import {config, LayersIcons} from "./config";
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import advancedFormat from 'dayjs/esm/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
import './chartjs-adapter-dayjs';

export function generateChartConfig(rawData, duration, metrics, isSwell) {
  let m, yScales = {}, xScale = {
    display: true,
    type: "time",
    time: {
      unit: (duration === 60 || duration === 36)  ? 'minute': 'hour',
      tooltipFormat: "DD/MM/YYYY\nHH[h]mm",
      displayFormats: {
        hour: 'HH[h]mm',
        minute: 'HH[h]mm',
        day: 'ddd DD/MM [-] HH[h]mm'
      }
    },
    scaleLabel: {
      display: true,
    },
    ticks: {
      source: 'labels',
      includeBounds: false,
    }
  };

  var cfg = {
    type: 'line',
    data: generateChartData(rawData, metrics, isSwell, duration),
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        tooltip: {
          yAlign: 'center',
          caretPadding: 25,
          callbacks: {
            label: function (tooltipItem) {
              if (tooltipItem.chart.canvas.id === "sensor_chart_wind") {
                let value = parseInt(tooltipItem.formattedValue);
                if (value < 0) {
                  value = value + 360
                }
                return tooltipItem.dataset.label + " : " +
                  (tooltipItem.dataset.label.indexOf('V') === 0 ?  (value.toString() + ' nds') : ((value % 360).toString() + '°'));
              } else {
                return tooltipItem.dataset.label + " : " + tooltipItem.formattedValue;
              }
            }
          }
        }
      },
      transitions: {
        active: {
          animation: {
            duration: 0
          }
        }
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
      radius: 0,
    }
  }

  for (m = 0; m < metrics.length; m++) {
    yScales['y' + (m === 0 ? '' : m)] = {
      type: 'linear',
      display: true,
      scaleLabel: {
        display: true,
      },
      ticks: {
        // major: {enabled: true},
        includeBounds: false,
        color: getMetricColor(metrics[m]),
        stepSize: getStepSizeY(metrics[m], duration),
        callback: labelWithUnit(metrics[m]),
        font: {
          size: 10,
        }
      },
      position: m === 0 ? 'left' : 'right',
      grid: {
        display: true,
        drawBorder: true,
        drawOnChartArea: m === 0,

      },
      ...scaleRange(metrics[m], duration, computeMinMax(cfg.data.datasets), isSwell)
    };
  }

  cfg.options.scales = {
    x: xScale,
    ...yScales
  };

  return cfg;
}

function getMetricColor(metric) {
  return "#333"; //config.chartColors[metric].moy;
}

function labelWithUnit(metric) {
  return function (val) {
    let unit;
    if (metric === 'ws') {
      unit = ' nds';
    } else if (metric === 'wd' || metric === 'sd') {
      unit = '°';
      val = val < 0 ? (360 + Math.round(val)) : (Math.round(val) % 360);
    } else if (metric === 't') {
      if (Math.abs(val) > 1 && Math.abs(val) < 10) {
        val = val.toPrecision(2)
      } else if (Math.abs(val) > 9) {
        val = val.toPrecision(3)
      } else {
        val = val.toPrecision(2)
      }
      val = Math.round(val)
      unit = ' °C'
    } else if (metric === 'sh') {
      unit = ' m';
    } else if (metric === 'p') {
      unit = ' hPa';
    } else if (metric === 'wv') {
      val = val.toPrecision(1)
      unit = ' m/s';
    } else if (metric === 'sp') {
      unit = ' s';
    }
    return val + unit
  }
}

function generateChartData(rawData, fields, isSwell, duration) {
  var graphDatasets = {}, graphTimestamps = [], d, prop, f;
  for (d of rawData) {
    const isSwellSensor = d['sh'] && d['sh']['moy'];
    graphTimestamps.push(d.ts);
    for (f of fields) {
      if (d[f]) {
        for (prop in d[f]) {
          graphDatasets[f + prop] = graphDatasets[f + prop] || {
            label: graphDatasetLabel(f, prop, isSwell, isSwellSensor),
            color: (config.chartColors[f][prop] || '#000000'),
            values: []
          };
          graphDatasets[f + prop].values.push({x: dayjs.unix(d.ts), y: d[f][prop]});
        }
      }
    }
  }
  let skippedTicks = isSwell ? 0 : (duration === 60 ? 4 : 2);
  let hoverElt = document.createElement('IMG');
  hoverElt.src = LayersIcons['graph-cursor'];
  hoverElt.alt = 'img';

  return {
    labels: graphTimestamps.map(function (ts) {
      if (isSwell) {
        return dayjs.unix(ts);
      } else {
        let stepInSeconds = duration === 60  ? 600 : (duration === 36 ? 1800 : 7200);
        return dayjs.unix(ts - ts % stepInSeconds);
      }
    }).slice(skippedTicks),
    datasets: Object.keys(graphDatasets).map(function (ds, i) {
      return {
        ref: ds,
        label: graphDatasets[ds].label,
        data: graphDatasets[ds].values,
        borderColor: graphDatasets[ds].color,
        hidden: isLegendHidden(ds),
        fill: false,
        tension: 0.4,
        yAxisID: 'y' + (fields.indexOf(fieldRef(ds)) === 0 ? '' : fields.indexOf(fieldRef(ds))),
        pointStyle: hoverElt,
        pointRadius: 0,
        pointHoverRadius: 2000,
        hoverBorderColor: "#333333",
      }
    })
  };
}

function isLegendHidden(type){
  if( type === "wsmax"){
    return false;
  }
  else return type.indexOf('moy') === -1;
}

function fieldRef(ref) {
   return (ref.indexOf("w") === 0 || ref.indexOf("s") === 0) ? ref.substring(0, 2) : ref.substring(0, 1);
}

function computeMinMax(datasets) {
  let minMax = {}, dataset, datasetValues, field, min, max, med;
  for (dataset of datasets) {
    field = fieldRef(dataset.ref), datasetValues = dataset.data.map(function(d) {return d.y;});
    min = Math.min(...datasetValues);
    max = Math.max(...datasetValues);
    if (!minMax[field]) {
      minMax[field] = {};
    }
    if (field === 'wd') {
      med = median(datasetValues);
      if (minMax[field]['med']) {
        minMax[field]['med'].push(med);
      } else {
        minMax[field]['med'] = [med];
      }
    }

    if (!minMax[field].min || min < minMax[field].min) {
      minMax[field].min = min;
    }
    if (!minMax[field].max || max > minMax[field].max) {
      minMax[field].max = max;
    }
  }
  return minMax;
}

function scaleRange(field, duration, minMax, isSwell) {
  let range, absMin, absMax, sMin, sMax, scaleRng = {};
  if (field === 'wv') {
    scaleRng = {
      min: -5,
      max: 5
    }
  } else if (field === 'sp') {
    scaleRng = {
      min: 0,
      max: 30
    }
  } else if (field === 'sd') {
    scaleRng = {
      min: 0,
      max: 360,
    }
  } else if (field === 'sh') {
    range = 10
    sMin = minMax[field].min;
    sMax = minMax[field].max;
    scaleRng = {
      min: Math.max(0, Math.round((sMin + sMax - range) / 2)),
      max: Math.max(10, Math.round((sMin + sMax + range) / 2)),
    }
  } else if (field === 't') {
    if (isSwell) {
      range = 10
    } else {
      range = 30
    }

    sMin = minMax[field].min;
    sMax = minMax[field].max;
    scaleRng = {
      min: Math.round((sMin + sMax - range) / 2),
      max: Math.round((sMin + sMax + range) / 2),
    }
  } else if (field === 'p') {
    range = 20
    sMin = minMax[field].min;
    sMax = minMax[field].max;
    scaleRng = {
      min: Math.round((sMin + sMax - range) / 2),
      max: Math.round((sMin + sMax + range) / 2)
    }
  } else if (field === 'wd') {
    let med = mean(minMax[field].med), amplitude = duration === 144 ? 400 : 200;
    scaleRng = {
      min: Math.round((med - (amplitude / 2)) / 10) * 10,
      max: med + (amplitude / 2)
    }
  } else if (field === 'ws') {
    if (duration === 60 || duration === 36 || duration === 144) {
      range = duration === 144 ? 70 : 40;
      absMin = 0;
      absMax = 200;
      sMin = Math.max(absMin, minMax[field].min);
      sMax = Math.min(absMax, minMax[field].max);
      scaleRng = {
        min: (sMin + sMax - range) / 2 < absMin ? absMin : Math.round((sMin + sMax - range) / 2),
        max: (sMin + sMax - range) / 2 < absMin ? range : Math.round((sMin + sMax + range) / 2)
      }
    }
  }
  // console.log('computed scale range - field : ' + field + ' - duration : ' + duration + ' - minMax : ' +
  //   JSON.stringify(minMax) + ' - isSwell : ' + isSwell + ' - scale range : ' + JSON.stringify(scaleRng));
  return scaleRng;
}

function getStepSizeY(metrics, duration) {
  if (metrics === 't' || metrics === 'p' || metrics === 'wv') {
    return 1;
  } else if (metrics === 't') {
    return 4;
  } else if (metrics === 'ws') {
    return duration < 144 ? 2 : 4;
  } else if (metrics === 'sd') {
      return 20;
  } else if (metrics === 'wd') {
    if (duration === 60 || duration === 36) {
      return 10;
    } else if (duration === 144) {
      return 20;
    }
  }
}

function positiveValue(rawData) {
  for (let d = 0; d < rawData.length; d++) {
    if (rawData[d]["wd"].moy < 0) {
      rawData[d]["wd"].moy = rawData[d]["wd"].moy + 360
    }
    if (rawData[d]["wd"].min < 0) {
      rawData[d]["wd"].min = rawData[d]["wd"].min + 360
    }
    if (rawData[d]["wd"].max < 0) {
      rawData[d]["wd"].max = rawData[d]["wd"].max + 360
    }
  }
  return rawData
}


function graphDatasetLabel(field, prop, isSeaTemp, isSwellSensor) {
  if (field === 'ws') {
    return "V. " + prop + "";
  } else if (field === 'wd') {
    return "Dir. " + prop + "";
  } else if (field === 'wv') {
    return "V. vert.";
  } else if (field === 't') {
    return "T° " + (isSeaTemp ? `mer (${isSwellSensor ? 'à 40cm' : 'à 2m'} sous la surface)` : "air");
  } else if (field === 'sh') {
    return "Hauteur de la houle";
  } else if (field === 'sp') {
    return "Période de la houle";
  } else if (field === 'p') {
    return "Pression atmosphérique";
  } else if (field === 'sd') {
    return "Direction de la houle";
  } else {
    return prop;
  }
}

function chartTitle(metric) {
  if (metric === 'ws') {
    return "Vitesse du vent";
  } else if (metric === 'wd') {
    return "Direction du vent";
  } else if (metric === 'wv') {
    return "Vitesse verticale du vent";
  } else if (metric === 't') {
    return "Température de l'air";
  } else if (metric === 'p') {
    return "Pression atmosphérique";
  } else {
    return metric;
  }
}
