import * as L from "leaflet/dist/leaflet";
import {Point, toPoint} from "leaflet/src/geometry/Point";
import {Bounds, toBounds} from "leaflet/src/geometry/Bounds";

// Patched version of the Leaflet Bounds type prototype to prevent the zoom/pan bug when user location is shown
export function patchLeafletBounds(leafletBounds) {
  leafletBounds.prototype = {
    // @method extend(point: Point): this
    // Extends the bounds to contain the given point.
    extend: function (point) { // (Point)
      point = toPoint(point);

      // @property min: Point
      // The top left corner of the rectangle.
      // @property max: Point
      // The bottom right corner of the rectangle.
      if (!this.min && !this.max) {
        this.min = point.clone();
        this.max = point.clone();
      } else {
        this.min.x = Math.min(point.x, this.min.x);
        this.max.x = Math.max(point.x, this.max.x);
        this.min.y = Math.min(point.y, this.min.y);
        this.max.y = Math.max(point.y, this.max.y);
      }
      return this;
    },

    // @method getCenter(round?: Boolean): Point
    // Returns the center point of the bounds.
    getCenter: function (round) {
      return new Point(
        (this.min.x + this.max.x) / 2,
        (this.min.y + this.max.y) / 2, round);
    },

    // @method getBottomLeft(): Point
    // Returns the bottom-left point of the bounds.
    getBottomLeft: function () {
      return new Point(this.min.x, this.max.y);
    },

    // @method getTopRight(): Point
    // Returns the top-right point of the bounds.
    getTopRight: function () { // -> Point
      return new Point(this.max.x, this.min.y);
    },

    // @method getTopLeft(): Point
    // Returns the top-left point of the bounds (i.e. [`this.min`](#bounds-min)).
    getTopLeft: function () {
      return this.min; // left, top
    },

    // @method getBottomRight(): Point
    // Returns the bottom-right point of the bounds (i.e. [`this.max`](#bounds-max)).
    getBottomRight: function () {
      return this.max; // right, bottom
    },

    // @method getSize(): Point
    // Returns the size of the given bounds
    getSize: function () {
      return this.max.subtract(this.min);
    },

    // @method contains(otherBounds: Bounds): Boolean
    // Returns `true` if the rectangle contains the given one.
    // @alternative
    // @method contains(point: Point): Boolean
    // Returns `true` if the rectangle contains the given point.
    contains: function (obj) {
      var min, max;

      if (typeof obj[0] === 'number' || obj instanceof Point) {
        obj = toPoint(obj);
      } else {
        obj = toBounds(obj);
      }

      if (obj instanceof Bounds) {
        min = obj.min;
        max = obj.max;
      } else {
        min = max = obj;
      }

      return (min.x >= this.min.x) &&
        (max.x <= this.max.x) &&
        (min.y >= this.min.y) &&
        (max.y <= this.max.y);
    },

    // @method intersects(otherBounds: Bounds): Boolean
    // Returns `true` if the rectangle intersects the given bounds. Two bounds
    // intersect if they have at least one point in common.
    intersects: function (bounds) { // (Bounds) -> Boolean
      bounds = toBounds(bounds);
      // console.log('overriddenxxxxx leaflet - this.min : ' + this.min + ' - this.max : ' + this.max +
      //   ' - bounds : ' + JSON.stringify(bounds));
      var min = this.min,
        max = this.max,
        min2 = bounds.min,
        max2 = bounds.max,
        xIntersects, yIntersects;

      if (min2 && max2) {
        xIntersects = (max2.x >= min.x) && (min2.x <= max.x),
          yIntersects = (max2.y >= min.y) && (min2.y <= max.y);
      } else {
        xIntersects = false, yIntersects = false;
      }
      return xIntersects && yIntersects;
    },

    // @method overlaps(otherBounds: Bounds): Boolean
    // Returns `true` if the rectangle overlaps the given bounds. Two bounds
    // overlap if their intersection is an area.
    overlaps: function (bounds) { // (Bounds) -> Boolean
      bounds = toBounds(bounds);

      var min = this.min,
        max = this.max,
        min2 = bounds.min,
        max2 = bounds.max,
        xOverlaps = (max2.x > min.x) && (min2.x < max.x),
        yOverlaps = (max2.y > min.y) && (min2.y < max.y);

      return xOverlaps && yOverlaps;
    },

    isValid: function () {
      return !!(this.min && this.max);
    }
  };
}
