const _temp0 = require("../img/nav/amp-map.svg");
const _temp1 = require("../img/nav/arrow.svg");
const _temp2 = require("../img/nav/arrow_gray.svg");
const _temp3 = require("../img/nav/favoris.svg");
const _temp4 = require("../img/nav/fleche-vague.svg");
const _temp5 = require("../img/nav/fleche-vent.svg");
const _temp6 = require("../img/nav/info.svg");
const _temp7 = require("../img/nav/legend.svg");
const _temp8 = require("../img/nav/meteo.svg");
const _temp9 = require("../img/nav/meteo_previ.svg");
const _temp10 = require("../img/nav/meteo_select.svg");
const _temp11 = require("../img/nav/meteogramme-prevision.svg");
const _temp12 = require("../img/nav/meteogramme.svg");
const _temp13 = require("../img/nav/temp.svg");
const _temp14 = require("../img/nav/tide-map.svg");
const _temp15 = require("../img/nav/tide.svg");
const _temp16 = require("../img/nav/waves-prevision.svg");
const _temp17 = require("../img/nav/waves.svg");
const _temp18 = require("../img/nav/webcam-map.svg");
const _temp19 = require("../img/nav/webcam.svg");
const _temp20 = require("../img/nav/wind.svg");
const _temp21 = require("../img/nav/wind_prevision.svg");
module.exports = {
  "amp-map": _temp0,
  "arrow": _temp1,
  "arrow_gray": _temp2,
  "favoris": _temp3,
  "fleche-vague": _temp4,
  "fleche-vent": _temp5,
  "info": _temp6,
  "legend": _temp7,
  "meteo": _temp8,
  "meteo_previ": _temp9,
  "meteo_select": _temp10,
  "meteogramme-prevision": _temp11,
  "meteogramme": _temp12,
  "temp": _temp13,
  "tide-map": _temp14,
  "tide": _temp15,
  "waves-prevision": _temp16,
  "waves": _temp17,
  "webcam-map": _temp18,
  "webcam": _temp19,
  "wind": _temp20,
  "wind_prevision": _temp21
}