import MicroModal from "micromodal";
import {Map} from "leaflet/src/map/Map";
import {TileLayer} from "leaflet/src/layer/tile/TileLayer";
import {Marker} from "leaflet/src/layer/marker/Marker";
import {Icon} from "leaflet/src/layer/marker/Icon";
import {IconDefault} from "leaflet/src/layer/marker/Icon.Default";
import {tns} from "tiny-slider/src/tiny-slider";
import {Chart, registerables} from 'chart.js';
import {waveSensorRef, sensorsRefs, asides, LayersIcons, waterTempSensorsRefs, waterTempSensorId} from "./config";
import {getAsync, postAsync} from "./utils";
import {generateChartConfig} from "./charts";
import dayjs from "dayjs/esm";
import router from "./router";

Chart.register(...registerables);
IconDefault.prototype.options.iconUrl = LayersIcons['marker-icon'];
IconDefault.prototype.options.iconRetinaUrl = LayersIcons['marker-icon-2x'];
IconDefault.prototype.options.shadowUrl = LayersIcons['marker-shadow'];

export function sensorDetails(d) {
  console.log('sensorDetails:' + d.category);
  let sensor;
  if (waveSensorRef && d.nid === waveSensorRef.nid) {
    sensor = waveSensorRef;
  } else if (d.nid === waterTempSensorId) {
    sensor = waterTempSensorsRefs[0];
  } else {
    sensor = sensorsRefs[d.nid];
  }

  document.querySelector("#map_modal").setAttribute("data-modal", "wind_sensor");
  document.querySelector("#map_modal_title").innerHTML = (sensor.label +"<span>Observations</span>");
  document.querySelector("#map_modal #header_links").innerHTML = renderLinks(sensor, 'header');
  document.querySelector("#map_modal_content").innerHTML =
    '<section>' +
    '<div id="charts_actions">' +
    '<button type="button" onclick="wm.displaySensorCharts(this, ' + d.nid + ', \'' + d.category + '\', 120, 60);" class="active">2 dernières heures</button>' +
    '<button type="button" onclick="wm.displaySensorCharts(this, ' + d.nid + ', \'' + d.category + '\', 600, 36);">Graphique 6h</button>' +
    '<button type="button" onclick="wm.displaySensorCharts(this, ' + d.nid + ', \'' + d.category + '\', 600, 144);">Graphique 24h</button>' +
    '<button type="button" onclick="wm.displaySensorCharts(this, ' + d.nid + ', \'' + d.category + '\', 600, 1152);">8 derniers jours</button>' +
    '</div>' +
    '<div id="charts_wrapper"></div>' +
    '</section>' +
    '<aside>' + renderAside(sensor) + '</aside>'
  MicroModal.show('map_modal', {disableScroll: true, onClose: () => { router.navigate("/"); }});
  displaySensorCharts(null, d.nid, d.category, 120, 60);
  // initAsideMap();
  initAsideSlides();
}

export function displaySensorCharts(btn, sensorId, sensorCategory, period, duration) {
  document.querySelector("#map_modal_content #charts_wrapper").innerHTML =
    '<p class="loading">Chargement des données en cours...</p>';
  let isSwell = (waveSensorRef && sensorId === waveSensorRef.nid),
    isWaterTemp = sensorId === waterTempSensorId, apiDuration;
  if (isSwell) {
    apiDuration = duration === 60 ? 4 : (duration === 36 ? 12 : duration === 1152 ? 384 : 48);
  } else if (isWaterTemp) {
    apiDuration = duration === 60 ? 2 : (duration === 36 ? 3 : duration === 1152 ? 40 : 5);
  } else {
    apiDuration = duration;
  }

  if (isNewGeneration(sensorId, sensorCategory)) {
    getAsync(`https://backend.windmorbihan.com/observations/chart.json?sensor=${sensorId}&time_frame=${duration}`,
      (resp) => processChartData(resp, sensorId, sensorCategory, duration, isSwell, isWaterTemp));
  } else {
    postAsync("https://obs.windmorbihan.com/mesures/get_graph_sensor_data",
      "settings[cid]=" + sensorId + "&settings[interval]=-1&settings[type]=" + ((isSwell || isWaterTemp) ? 0 : period) + "&settings[limit]=" + apiDuration,
      (resp) => processChartData(resp, sensorId, sensorCategory, duration, isSwell, isWaterTemp)
    );
  }

  if (btn) {
    var activeBtn = document.querySelector("#map_modal_content #charts_actions .active");
    if (activeBtn) {
      activeBtn.classList.remove('active');
    }
    btn.classList.add('active');
  }
}

function isNewGeneration(sensorId, sensorCategory) {
  return sensorCategory === 'mf' || /^\d{1,3}$/.test(sensorId);
}

function processChartData(resp, sensorId, sensorCategory, duration, isSwell, isWaterTemp) {
  let data = (isNewGeneration(sensorId, sensorCategory) ? resp : JSON.parse(resp).reverse()), ctx, i, windMetrics = ['ws', 'wd'],
    otherMetrics = isSwell ? ['sh', 'sp', 'sd', 't'] : ['t', 'sh', 'sp', 'sd', 'wv', 'p'];
  let rawData = (isSwell || isWaterTemp) ? data : cleanUpDataset(data);
  if (rawData && rawData.length) {
    if (document.querySelector('.radio-container')) {
      document.querySelector('.radio-container').remove();
    }
    document.querySelector("#map_modal_content #charts_wrapper").innerHTML = '';
    if (duration === 1152) {
      let seperateData = generateSeperateChartData(rawData);
      let separateAvailableMetrics = getseparateAvailableMetrics(seperateData, windMetrics, otherMetrics)
      generateRadioCollection(seperateData, separateAvailableMetrics, isSwell || isWaterTemp, otherMetrics);
      let radioCollectionList = document.querySelectorAll('.radio-container_item input');
      initChart(radioCollectionList, seperateData, separateAvailableMetrics, isSwell || isWaterTemp, otherMetrics)
    } else {
      let availableMetrics = getAvailableMetric(rawData, windMetrics, otherMetrics);
      let availWindMetrics = availableMetrics[0];
      let availOtherMetrics = availableMetrics[1];
      let m = availableMetrics[2];
      if (availWindMetrics.length) {
        document.querySelector("#map_modal_content #charts_wrapper")
          .insertAdjacentHTML('beforeend', '<div class="chart_container"><canvas id="sensor_chart_wind"></canvas></div>');
        ctx = document.getElementById('sensor_chart_wind').getContext('2d');
        new Chart(ctx, generateChartConfig(translateDirectionValues(rawData, 360), duration, availWindMetrics, isSwell || isWaterTemp));
      }
      if (availOtherMetrics.length) {
        let sortedOther = availOtherMetrics.sort(function (m) {
          return otherMetrics.indexOf(m);
        });
        for (m of availOtherMetrics) {
          document.querySelector("#map_modal_content #charts_wrapper")
            .insertAdjacentHTML('beforeend', '<div class="chart_container"><canvas id="sensor_chart_' + m + '"></canvas></div>');
          ctx = document.getElementById('sensor_chart_' + m).getContext('2d');
          new Chart(ctx, generateChartConfig(rawData, duration, [m], isSwell || isWaterTemp));
        }
      }
    }
  }
}

/* ------ get available metric ------*/
function getseparateAvailableMetrics(seperateData, windMetrics, otherMetrics) {
  let separateAvailableMetrics = {};
  for (let s in seperateData) {
    if (!separateAvailableMetrics[s]) {
      separateAvailableMetrics[s] = [];
    }
    let availableMetrics = getAvailableMetric(seperateData[s], windMetrics, otherMetrics);

    separateAvailableMetrics[s].push(availableMetrics);
  }
  return separateAvailableMetrics;
}

function getAvailableMetric(rawData, windMetrics, otherMetrics) {
  let availableMetrics = Object.keys(rawData[0]), availWindMetrics = [], availOtherMetrics = [], m;
  for (m of availableMetrics) {
    if (windMetrics.indexOf(m) !== -1 && Object.values(rawData[0][m])[0]) {
      availWindMetrics.push(m);
    } else if (otherMetrics.indexOf(m) !== -1 && Object.values(rawData[0][m])[0]) {
      availOtherMetrics.push(m);
    }
  }
  return [availWindMetrics, availOtherMetrics, m];
}

/* ------ generate seperate dataset for 8 days view ------ */
function generateSeperateChartData(rawData) {
  let today = dayjs();
  let todayFormated = today.format("ddd DD/MM")
  let lastDate = today.subtract(7, 'day')
  let lastDateTS = lastDate.format("x")

  let radioCollectionData = {};

  for (let i = 0; i < rawData.length; i++) {
     if(rawData[i]['ts']*1000 > lastDateTS){
      let formatedDate = dayjs.unix(rawData[i]['ts']).format("ddd DD/MM")
      if (formatedDate !== todayFormated) {
        if (!radioCollectionData[formatedDate]) {
          radioCollectionData[formatedDate] = [];
        }
        radioCollectionData[formatedDate].push(rawData[i]);
      }
    }
   }
  return radioCollectionData;
}

/* ------ Generate chart  ------*/
function initChart(radioCollectionList, seperateData, separateAvailableMetrics, isSwell, otherMetrics) {
  for (let i = 0; i < radioCollectionList.length; i++) {
    if (radioCollectionList[i].checked) {
      let availWindMetrics = separateAvailableMetrics[radioCollectionList[i].value][0][0];
      let availOtherMetrics = separateAvailableMetrics[radioCollectionList[i].value][0][1];
      let m = separateAvailableMetrics[radioCollectionList[i].value][0][2];
      if (availWindMetrics.length) {
        document.querySelector("#map_modal_content #charts_wrapper")
          .insertAdjacentHTML('beforeend', '<div class="chart_container"><canvas id="sensor_chart_wind"></canvas></div>');
        let ctx = document.getElementById('sensor_chart_wind').getContext('2d');
        new Chart(ctx, generateChartConfig(translateDirectionValues(seperateData[radioCollectionList[i].value], 360), 144, availWindMetrics, isSwell));
      }
      if (availOtherMetrics.length) {
        let sortedOther = availOtherMetrics.sort(function (m) {
          return otherMetrics.indexOf(m);
        });
        for (m of availOtherMetrics) {
          document.querySelector("#map_modal_content #charts_wrapper")
            .insertAdjacentHTML('beforeend', '<div class="chart_container"><canvas id="sensor_chart_' + m + '"></canvas></div>');
          let ctx = document.getElementById('sensor_chart_' + m).getContext('2d');
          new Chart(ctx, generateChartConfig(seperateData[radioCollectionList[i].value], 144, [m], isSwell));
        }
      }
    }
  }
}

function generateSeperateChart(d, seperateData, separateAvailableMetrics, isSwell, otherMetrics) {
  let availWindMetrics = separateAvailableMetrics[0][0];
  let availOtherMetrics = separateAvailableMetrics[0][1];
  let m = separateAvailableMetrics[0][2];
  if (availWindMetrics.length) {
    document.querySelector("#map_modal_content #charts_wrapper")
      .insertAdjacentHTML('beforeend', '<div class="chart_container"><canvas id="sensor_chart_wind"></canvas></div>');
    let ctx = document.getElementById('sensor_chart_wind').getContext('2d');
    new Chart(ctx, generateChartConfig(translateDirectionValues(seperateData[d], 360), 144, availWindMetrics, isSwell));
  }
  if (availOtherMetrics.length) {
    let sortedOther = availOtherMetrics.sort(function (m) {
      return otherMetrics.indexOf(m);
    });
    for (m of availOtherMetrics) {
      document.querySelector("#map_modal_content #charts_wrapper")
        .insertAdjacentHTML('beforeend', '<div class="chart_container"><canvas id="sensor_chart_' + m + '"></canvas></div>');
      let ctx = document.getElementById('sensor_chart_' + m).getContext('2d');
      new Chart(ctx, generateChartConfig(seperateData[d], 144, [m], isSwell));
    }
  }

}

/* ------ Generate radio collection  ------*/
function generateRadioCollection(data, separateAvailableMetrics, isSwell, otherMetrics) {
  let radioListContainer = generateRadioCollectionContainer();
  for (let d in data) {
    let radioContainer = generateRadioContainer(radioListContainer, d)
    generateRadioInput(radioContainer, d, data, separateAvailableMetrics[d], isSwell, otherMetrics);
    generateRadioLabel(radioContainer, d);
  }
  reverseChildren(radioListContainer);
  let firstRadio = radioListContainer.firstChild;
  let firstRadioInput = firstRadio.querySelector('input');
  firstRadioInput.checked = true;
}

function generateRadioCollectionContainer() {
  let div = document.createElement("div");
  div.classList.add('radio-container');
  let container = document.querySelector('#charts_actions');
  return container.parentNode.insertBefore(div, container.nextSibling);
}

function generateRadioContainer(radioListContainer, d) {
  let radioContainer = document.createElement("div");
  radioContainer.classList.add('radio-container_item');
  radioContainer.id = 'container-' + d;
  radioListContainer.append(radioContainer);
  return radioContainer;
}

function generateRadioInput(radioContainer, d, data, separateAvailableMetrics, isSwell, otherMetrics) {
  let radioInput = document.createElement("input");
  radioInput.id = 'input-' + d.replace(/\s|\.|\//g, '');
  radioInput.type = 'radio';
  radioInput.name = 'date';
  radioInput.value = d;
  radioInput.onclick = function (event) {
    let lastCanvasList = document.querySelectorAll('.chart_container');
    for (let i = 0; i < lastCanvasList.length; i++) {
      lastCanvasList[i].remove();
    }
    generateSeperateChart(d, data, separateAvailableMetrics, isSwell, otherMetrics)

  };
  radioContainer.append(radioInput);
}

function generateRadioLabel(radioContainer, d) {
  let radioLabel = document.createElement("label");
  radioLabel.id = 'label-' + d.replace(/\s|\.|\//g, '');
  radioLabel.setAttribute('for', 'input-' + d.replace(/\s|\.|\//g, ''));
  radioLabel.innerHTML = d;
  radioContainer.append(radioLabel);
}

function reverseChildren(parent) {
  for (let i = 1; i < parent.childNodes.length; i++) {
    parent.insertBefore(parent.childNodes[i], parent.firstChild);
  }
}

function cleanUpDataset(data) {
  let dataset = cleanUpWindSpeedDataset(data);
  return cleanUpWindDirectionDataset(dataset);
}

function cleanUpWindDirectionDataset(data) {
  let validData = [];
  for (let i = 0; i < data.length; i++) {
    if ((data[i]["wd"].min && data[i]["wd"].moy && data[i]["wd"].max) || (!data[i]["wd"].min && data[i]["wd"].moy && !data[i]["wd"].max)) {
      validData.push(data[i]);
    }
  }
  return validData;
}

export function translateDirectionValues(data, range) {
  let i, prevValue, valueDiff, field = 'wd';
  if (data.length && data[0][field]) {
    for (i = 0; i < data.length; i++) {
      valueDiff = prevValue ? (data[i][field].moy - prevValue) : 0;
      if (Math.abs(valueDiff) > range / 2) {
        if (valueDiff > 0) {
          // saut vers le haut : on decale les points suivants de "range" vers le bas (en detectant d'autres sauts s'il y en a)
          return [...data.slice(0, i), ...translateDirectionValues(translatedValues(field, data.slice(i), -range), range)];
        } else {
          // saut vers le bas : on decale les points suivants de "range" vers le haut (en detectant d'autres sauts s'il y en a)
          return [...data.slice(0, i), ...translateDirectionValues(translatedValues(field, data.slice(i), range), range)];
        }
      }
      prevValue = data[i][field].moy;
    }
  }
  return data;
}

function translatedValues(field, values, offset) {
  let i, translatedValues = [...values];
  for (i = 0; i < values.length; i++) {
    translatedValues[i][field] = {
      min: Math.round(values[i][field].min + offset), moy: Math.round(values[i][field].moy + offset), max: Math.round(values[i][field].max + offset)
    };
  }
  return translatedValues;
}

function cleanUpWindSpeedDataset(data) {
  let validData = [];
  let windSpeed = 'ws';
  let limitWindSpeed = 200;
  let previousWindSpeed = null;
  for (let i = 0; i < data.length; i++) {
    if (data[i][windSpeed].max.length === 0) {
      validData.push(data[i])
    }
    if (previousWindSpeed == null) {
      previousWindSpeed = data[i][windSpeed].max;
    }
    if ((data[i][windSpeed].max < (2 * previousWindSpeed)) && (data[i][windSpeed].max < limitWindSpeed)) {
      previousWindSpeed = data[i][windSpeed].max;
      validData.push(data[i])
    } else {
      previousWindSpeed = data[i][windSpeed].max;
    }
  }
  return validData;
}

function renderLinks(sensor, section) {
  let linksMarkup = '', sectionLinks = (sensor.image_links_list || []).filter(l => l.section === section);
  if (sectionLinks.length) {
    linksMarkup += renderSectionLinks('partners_links', sectionLinks);
  } else if (asides[sensor.nid]) {
    for (let a of asides[sensor.nid]) {
      if (a.links) {
        linksMarkup += '<div class="partners_links">' + a.links.map(function (l) {
          return '<a href="' + l.href + '" target="_blank"><img src="' + l.img + '" alt=""/></a>';
        }).join('') + '</div>';
      }
    }
  }
  return linksMarkup + '<button class="modal__close" aria-label="Fermer" data-micromodal-close></button>';
}

function renderSectionLinks(wrapperClass, sectionLinks) {
  return `<div class="${wrapperClass}">${sectionLinks.map(l => '<a href="' + l.link_url + '" target="_blank"><img src="' + l.image_url + '" alt=""/></a>').join('')}</div>`;
}

function renderAside(sensor) {
  let asideContent = '';
  if (sensor.description_content) {
    asideContent += sensor.description_content;
  }
  if (sensor.photos_list?.length) {
    asideContent += `<div id="aside_slides">${sensor.photos_list.map(p => '<div><img src="' + p + '" alt=""/></div>').join('')}</div>`;
  }

  const footerLinks = (sensor.image_links_list || []).filter(l => l.section === 'footer');

  if (footerLinks.length) {
    asideContent += renderSectionLinks('footer_links', footerLinks);
  }

  if (sensor.validAngle && sensor.validAngle.filter(deg => deg > 0).length) {
    const [a1, a2, a3] = sensor.validAngle;
    let deventPie, pieSegments = [];
    if (a2) {
      if (a3) {
        for (const segment of [[a1, a2, 'green'], [a2, a3, 'orange'], [a3, a1, 'red']]) {
          pieSegments.push(...pieSegment(segment[0], segment[1], segment[2]));
        }
        pieSegments = pieSegments.sort((s1, s2) => s1.from - s2.from).map(s => `${s.color} ${s.from}deg ${s.to}deg`).join(', ');
        deventPie = `<div class="devent_pie" style="border-radius: 100%; width: 180px; aspect-ratio: 1; background: conic-gradient(${pieSegments});"></div>`;
      } else {
        for (const segment of [[a1, a2, 'green'], [a2, a1, 'orange']]) {
          pieSegments.push(...pieSegment(segment[0], segment[1], segment[2]));
        }
        pieSegments = pieSegments.sort((s1, s2) => s1.from - s2.from).map(s => `${s.color} ${s.from}deg ${s.to}deg`).join(', ');
        deventPie = `<div class="devent_pie" style="border-radius: 100%; width: 180px; aspect-ratio: 1; background: conic-gradient(${pieSegments});"></div>`;
      }
    } else {
      for (const segment of [[a1, a3, 'green'], [a3, a1, 'red']]) {
        pieSegments.push(...pieSegment(segment[0], segment[1], segment[2]));
      }
      pieSegments = pieSegments.sort((s1, s2) => s1.from - s2.from).map(s => `${s.color} ${s.from}deg ${s.to}deg`).join(', ');
      deventPie = `<div class="devent_pie" style="border-radius: 100%; width: 180px; aspect-ratio: 1; background: conic-gradient(${pieSegments});"></div>`;
    }
    asideContent += ('<section class="sensor_devent">' +
        '<h2>Secteurs d\'utilisation</h2>' +
        deventPie +
        '<ul>' +
          '<li><strong>Vert</strong> : pas de dévent</li>' +
          '<li><strong>Orange</strong> : léger dévent</li>' +
          '<li><strong>Rouge</strong> : fort dévent</li>' +
        '</ul>' +
        '<p><em>Le vent en hauteur est supérieur au vent en surface. Ce différentiel est d’autant plus important que le vent est faible et que la masse d’air est stable.</em></p>' +
      '</section>');
  }
  return asideContent;
}

function pieSegment(fromAngle, toAngle, color) {
  if (fromAngle <= toAngle) {
    return [{from: fromAngle, to: toAngle, color: color}];
  } else {
    return [{from: fromAngle, to: 0, color: color}, {from: 0, to: toAngle, color: color}];
  }
}


function initAsideSlides() {
  var slidesElt = document.querySelector("#aside_slides");
  if (slidesElt) {
    tns({container: '#aside_slides', autoplay: true, controls: false, mode: 'gallery'});
  }
}
